<template>
  <div
    class="grid grid-cols-2 gap-x-1.5 gap-y-3 md:gap-3"
    style="grid-template-columns: auto 1fr; grid-template-rows: auto 1fr"
  >
    <div
      class="relative col-span-1 flex aspect-default h-fit w-[266px] items-center justify-center overflow-hidden rounded-lg bg-secondary md:row-span-2"
    />
    <div class="mx-3 mt-2 h-6 w-3/4 bg-secondary" />
    <ul class="col-span-2 flex h-fit flex-wrap gap-3 md:col-span-1">
      <li
        v-for="item in 4"
        :key="item"
        class="flex h-fit max-w-[104px] cursor-pointer select-none flex-col rounded-lg border border-primary bg-primary px-2 py-1 shadow-primary transition-all peer-hover:border-primary peer-hover:shadow-secondary peer-focus:shadow-secondary lg:min-w-1 lg:shadow-none"
      >
        <div class="mb-1 h-5 w-7 rounded-lg bg-secondary" />
        <div class="mb-1 h-4 w-8 rounded-lg bg-secondary" />
      </li>
    </ul>
  </div>
</template>
